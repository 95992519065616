import {html, render} from 'lit-html';
import '@material/mwc-snackbar';
import '@material/mwc-icon-button';

import './code-mirror.js';
import dedup from './util/dedup.js';

class ProvisionInput extends HTMLElement {
  static observedAttributes = ['clientid'];

  get clientId() {
    return this.getAttribute('clientid') || window.location.hash.slice(1,Infinity);
  }
  set clientId(v) {
    if (v===this.clientId) return;
    this.setAttribute('clientid', v);
    this.update();
  }

  constructor() {
    super();
    this.update = dedup(this.update.bind(this));
    this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    this.update();
  }

  attributeChangedCallback() {
    this.update();
  }

  async update() {
    const files = {
      'user': {
        name: 'user-data',
        mode: 'yaml',
        value: await this.getInitFile('user-data'),
      },
      'meta': {
        name: 'meta-data',
        mode: 'yaml',
        value: await this.getInitFile('meta-data'),
      },
      'grub': {
        name: 'grub.cfg',
        mode: 'shell',
        value: await this.getInitFile('grub.cfg'),
      },
    };

    const loadTemplateOnClick = async (e, k, v)=>{
      const t = this.shadowRoot.querySelector('.provisioning.'+k);
      if (t.value) {
        if (!confirm('Do you really want to overwrite contents?')) return;
      }
      t.value = await this.getInitTemplate(v.name);
      t.dispatchEvent(new Event('input'));
    };

    const filesHtml = Object.entries(files).map(([k, v])=>html`
      <h3>${v.name}</h3>
      <mwc-button raised @click=${e=>loadTemplateOnClick(e, k, v)}>load template</mwc-button>
      <code-mirror
        mode=${v.mode}
        theme="material-darker"
        class="provisioning ${k}"
        @change=${e=>this.setInitFile(v.name, e.target.value)}
        .init=${v.value}
      ></code-mirror>
    `);

    render(html`
      <style>
        code-mirror {
          resize: vertical;
          margin-bottom: 36px;
        }
        code-mirror.provisioning.user {
          height: 50em;
        }
        code-mirror.provisioning.meta {
          height: 10em;
        }
        code-mirror.provisioning.grub {
          height: 25em;
        }
      </style>
      <h2>Provisioning</h2>
      ${filesHtml}
      <mwc-snackbar id="sendfailed" labeltext="Can't reach server. Please retry later!"><mwc-icon-button id="iconButton" icon="close" slot="dismiss"></mwc-icon-button></mwc-snackbar>
    `, this.shadowRoot);
  }

  async getInitTemplate(name) {
    const variables = {
      "{{clientid}}": this.clientId,
      "{{serverhostname}}": window.location.hostname,
      "{{serverhost}}": window.location.host,
      "{{reporturl}}": new URL('/report/'+this.clientId, window.location).toString(),
    };

    const dataURL = new URL('/templates/'+name, window.location);
    return fetch(dataURL)
      .then(res=>res.ok?res.text():'')
      .then(text=>Object.entries(variables).reduce((p, [ck, cv])=>p.split(ck).join(cv), text));
  }
  async getInitFile(name) {
    if (!this.clientId) return '';
    const dataURL = new URL('/init/'+this.clientId+'/'+name, window.location);
    return fetch(dataURL)
      .then(res=>res.ok?res.text():'')
      .catch(e=>this.shadowRoot.querySelector('#sendfailed').show());
  }
  async setInitFile(name, content) {
    if (!this.clientId) return;
    const dataURL = new URL('/init/'+this.clientId+'/'+name, window.location);
    return fetch(dataURL, {method: 'PUT', headers: {'content-type': 'text/plain'}, body: content})
      .then(res=>{if (res.ok) return res.text(); throw new Error('invalid response code '+res.status)})
      .catch(e=>this.shadowRoot.querySelector('#sendfailed').show());
  }
}
customElements.define('provision-input', ProvisionInput);
