import {html, render} from 'lit-html';
import '@material/mwc-top-app-bar-fixed';

import './client-input.js';
import './log-report.js';
import './provision-input.js';
import mdl from './mdl.css';

class CurtinApp extends HTMLElement {
  get clientId() {
    return this.getAttribute('clientid') || window.location.hash.slice(1,Infinity);
  }
  set clientId(v) {
    if (v===this.clientId) return;
    window.location.hash = v;
    this.setAttribute('clientId', v);
    this.update();
  }

  constructor() {
    super();
    this.attachShadow({mode: 'open'});
    this.shadowRoot.adoptedStyleSheets = [...this.shadowRoot.styleSheets, mdl];
  }

  connectedCallback() {
    this.update();
    window.addEventListener('hashchange', _=>{
      this.clientId = window.location.hash.slice(1,Infinity);
    });
  }

  attributeChangedCallback() {
    this.update();
  }

  update() {
    render(html`
      <style>
        #header [slot=title] {
          display: flex;
          align-items: center;
          gap: 1ch;
        }
        #header [slot=title] span {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .sections-wrapper {
          /* two columns width + padading + gap */
          -max-width: calc((450px + 16px * 2) * 2 + 24px);
          -max-width: calc(100vw - 2 * 24px);
          margin: 24px;
          display: grid;
          gap: 24px;
          grid-template: "a a" auto
                         "b b" auto
                         "c c" auto;
        }

        .a { grid-area: a }
        .b { grid-area: b }
        .c { grid-area: c }

        /* sections-wrapper max width + 48px margins + 15px scrollbar */
        @media(min-width: 1200px) {
          .sections-wrapper {
            grid-template: "a c" auto
                           "b c" 1fr;
          }
        }
      </style>

      <mwc-top-app-bar-fixed id="header">
        <div slot="title">
          <img width="32" height="32" src="./assets/logo.svg">
          <span>Curtin App</span>
        </div>
      </mwc-top-app-bar-fixed>
      <main class="sections-wrapper">
        <div class="component-section a">
          <client-input clientid=${this.clientId} @clientid=${e=>this.clientId = e.detail.clientId}></client-input>
        </div>
        <div class="component-section b">
          <log-report clientid=${this.clientId}></log-report>
        </div>
        <div class="component-section c">
          <provision-input clientid=${this.clientId}></provision-input>
        </div>
      </main>
    `, this.shadowRoot);
  }

}
customElements.define('curtin-app', CurtinApp);
