export const dedup = (fn) => {
  let pending = Promise.resolve();
  let latestArgs;
  return async (...args) => {
    latestArgs = args;
    await pending.catch(e => {});
    if (latestArgs === args) {
      pending = fn(...latestArgs);
    }
    return pending;
  }
}
export default dedup;
