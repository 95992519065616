import {html, render} from 'lit-html';
import '@material/mwc-button';

import dedup from './util/dedup.js';

class LogReport extends HTMLElement {
  static observedAttributes = ['clientid'];

  get clientId() {
    return this.getAttribute('clientid') || window.location.hash.slice(1,Infinity);
  }
  set clientId(v) {
    if (v===this.clientId) return;
    this.setAttribute('clientid', v);
  }

  constructor() {
    super();
    this.update = dedup(this.update.bind(this));
    this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    const updateLoop = async (f)=>{
      if (!this.isConnected) return;
      await this.update().catch(e=>console.error(e));
      window.setTimeout(()=>f(f), 5000);
    }
    updateLoop(updateLoop);
  }

  attributeChangedCallback() {
    this.update();
  }

  async getLogs() {
    if (!this.clientId) return [];
    const reportURL = new URL('/report/'+this.clientId, window.location);
    return await fetch(reportURL).then(res=>res.ok?res.json():[]);
  }

  async update() {
    const log = await this.getLogs();
    const logMap = log.map(t=>new Map(Object.entries(t)));
    const structure = this.structurize(logMap);

    if (!structure.get('subiquity')) return render(html`
      <style>
        div>div div {
          border-left: 4ch solid transparent;
        }
      </style>
      <h2>Waiting for client...</h2>
    `, this.shadowRoot);

    const failedList = logMap.filter(t=>t.get('result')==='FAIL').map(t=>this.prettifyTask(t.get('name'), t));

    render(html`
      <style>
        div>div div {
          border-left: 4ch solid transparent;
        }
      </style>
      <mwc-button raised @click=${e=>this.deleteReports()}>clear client report</mwc-button>
      <h2>Tasks</h2>
      <h3>Failed</h3>
      ${failedList.length?failedList:'none'}
      <h3>Running</h3>
      ${this.visualize(structure.get('subiquity').get('children'))}
    `, this.shadowRoot);
  }

  structurize(tasks) {
    const structure = new Map();

    tasks.forEach(t=>{
      const path = t.get('name').split('/');
      path.reduce((p, c, i)=>{
        if (!p.get(c)) p.set(c, new Map([['children', new Map()]]));
        if (i === path.length - 1) p.set(c, new Map([
          ...t.entries(),
          ['children', p.get(c).get('children')],
        ]));

        return p.get(c).get('children');
      }, structure);
    });

    return structure;
  }

  visualize(structure) {
    const tasks = [...structure.entries()].reverse().map(([n,t], i)=>html`
      <div>
        ${this.prettifyTask(n, t)}
        ${t.get('result') !== 'SUCCESS' && t.get('children') ? this.visualize(t.get('children')) : ''}
      </div>
    `);
    return html`${tasks}`;
  }

  prettifyTask(name, t) {
    const icon = t.get('event_type')==='finish'?t.get('result')==='SUCCESS'?'✅':'❌':'🔵';
    return html`
      <div @click=${()=>console.log(t)}>
        ${icon} ${name}
      <div>
    `;
  }

  async deleteReports() {
    const reportURL = new URL('/report/'+this.clientId, window.location);
    await fetch(reportURL, {method:'DELETE'});
  }

}
customElements.define('log-report', LogReport);
