import {html, render} from 'lit-html';

import '@material/mwc-textfield';
import '@material/mwc-textarea';

class ClientInput extends HTMLElement {
  static observedAttributes = ['clientid'];
  static connectionRequest = fetch('./connection').then(res=>res.json());

  get clientId() {
    return this.getAttribute('clientid') || window.location.hash.slice(1,Infinity);
  }
  set clientId(v) {
    if (v===this.clientId) return;
    this.setAttribute('clientid', v);
    this.dispatchEvent(new CustomEvent('clientid', {detail: {clientId: this.clientId}}));
    this.update();
  }

  constructor() {
    super();
    this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    this.update();
  }

  attributeChangedCallback() {
    this.update();
  }

  async update() {
    const con = await ClientInput.connectionRequest;
    const serverhost = window.location.host;
    const serverhostname = window.location.hostname;
    const dhcpsettings = `dhcp-boot=grubnet,"${serverhost}",${con.ipv4||serverhostname}\ndhcp-match=set:efi-x86_64,option:client-arch,7\ndhcp-boot=tag:efi-x86_64,grubnet.efi,"${serverhost}",${con.ipv4||serverhostname}`;
    const reportUrl = new URL('/report/'+this.clientId, window.location);

    render(html`
      <style>
        mwc-textarea {
          height: 8rem;
        }
        li {
          margin-top: 1rem;
        }
      </style>
      <h2>Client</h2>
      <mwc-textfield
        label="ID"
        helper="Unique ID to identify the client, usually a mac address"
        outlined
        @input=${(e)=>this.clientId = e.target.value}
        value=${this.clientId}
      ></mwc-textfield>
      <p>Control and monitor PXE deployments using Curtin</p>
      <ol>
        <li>Configure your DHCP server to advertise our TFTP-Server</li>
        <mwc-textarea
          label="DNSMasq-Settings"
          outlined
          readonly
          value=${dhcpsettings}
          style="width: 100%; margin-top: 0.3rem"
        ></mwc-textarea>
        <li>Configure grub and cloud-init</li>
        <li>Add webhook reporting to your user-data config using the following url</li>
        <mwc-textfield
          label="Report URL"
          outlined
          readonly
          value=${reportUrl.toString()}
          style="width: 100%; margin-top: 0.3rem"
        ></mwc-textfield>
        <li>Profit!</li>
      </ol>
    `, this.shadowRoot);
  }
}
customElements.define('client-input', ClientInput);
