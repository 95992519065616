import {html, render} from 'lit-html';
import * as CM from 'codemirror';
window.CodeMirror = window.CodeMirror || CM.default;

let dynamicImportOrigin = new URL('https://unpkg.com/codemirror/');

export default class CodeMirrorElement extends HTMLElement {
  static get observedAttributes() {return ['theme', 'mode']}
  static get dynamicImportOrigin() {return dynamicImportOrigin}
  static set dynamicImportOrigin(v) {dynamicImportOrigin = v instanceof URL ? v : new URL(v, window.location)}

  get dynamicImportOrigin() {
    return this._dynamicImportOrigin;
  }
  set dynamicImportOrigin(v) {
    this._dynamicImportOrigin = v instanceof URL ? v : new URL(v, window.location);
    this.update();
  }

  get mode() {
    return this.getAttribute('mode') || 'javascript';
  }
  set mode(v) {
    this.setAttribute('mode', v);
    this.update();
  }

  get theme() {
    return this.getAttribute('theme') || 'default';
  }
  set theme(v) {
    this.setAttribute('theme', v);
    this.update();
  }

  get value() {
    return this.editor ? this.editor.doc.getValue() : this.textContent;
  }
  set value(v) {
    if (this.editor) this.editor.doc.setValue(v);
    else this.textContent = v;
  }
  set init(v) {
    this.preventChangeEvent = true;
    this.value = v;
    this.preventChangeEvent = false;
  }

  constructor() {
    super();
    this.attachShadow({mode: 'open'});
    this._dynamicImportOrigin = CodeMirrorElement.dynamicImportOrigin;
  }

  connectedCallback() {
    this.update();
  }

  attributeChangedCallback() {
    this.update();
  }

  update() {
    if (!this.isConnected) return;

    import(new URL(`./mode/${this.mode}/${this.mode}.js`, this.dynamicImportOrigin).toString()).then(res =>{
      this.editor.setOption('mode', this.mode);
    });

    render(html`
      <style>
        @import '${new URL(`./lib/codemirror.css`, this.dynamicImportOrigin).toString()}';
        @import '${new URL(`./theme/${this.theme}.css`, this.dynamicImportOrigin).toString()}';
        :host {display: block; height: 300px}
        .CodeMirror {height: 100%}
      </style>
    `, this.shadowRoot)

    if (!this.editor) {
      this.editor = new CodeMirror(this.shadowRoot, {
        value: this.value,
        mode:  this.mode,
        theme: this.theme,
      });
      this.editor.on('change', ()=>{
        if (this.preventChangeEvent) return;
        this.dispatchEvent(new Event('change', {bubbles: true, composed: true}));
      });
    }

    this.editor.setOption('theme', this.theme);
    this.editor.setOption('mode', this.mode);
  }
}
customElements.define('code-mirror', CodeMirrorElement);
